.Proiect {
	padding: 30px 0px;
	margin-bottom: 35px;
	a {
		text-decoration: none;
		color: inherit;
	}
	.container_proiect {
		position: relative;
		height: auto;
		display: block;
		div {
			margin-bottom: 10px;
		}
		.overlay {
			background: rgba(29, 34, 46, 0.7);
			backdrop-filter: blur(1px);
			padding: 35px 25px;
			position: sticky;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			border-radius: 10px;
			transition: all 0.3s;
			&:hover {
				backdrop-filter: blur(6px);
				cursor: default;
			}
		}
	}
}
.light_mode {
	.Proiect {
		h1 {
			color: #fefefe;
		}
		p {
			color: #ccc;
		}
	}
}
