body,
html {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	margin: 0px;
	img {
		user-drag: none;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-drag: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
	#root {
		height: 100%;
	}
}

// a{
//     color:#D12122;
//     text-decoration: none;
//     &:hover{
//         text-decoration: underline;
//     }
// }

.unselectable {
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

::selection {
	background: rgb(223, 47, 47);
	color: #eee;
}

section#continut_articol {
	margin-top: 36px;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 1.6rem;
		line-height: 2.8rem;
		margin: 0px;
		margin-bottom: 20px;
		font-weight: 100;
	}
	a {
		color: rgb(208, 32, 33);
		padding: 2px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	table {
		width: 100%;
		margin: 30px 0px;
		border-collapse: collapse;
		margin: 25px 0;
		font-size: 0.9em;
		td {
			padding-top: 14px;
			vertical-align: top;
			padding-left: 3px;
		}
		tbody tr {
			border-bottom: 1px solid rgba(100, 100, 100, 0.2);
		}

		tbody tr:nth-of-type(even) {
			background-color: rgba(100, 100, 100, 0.1);
		}

		tbody tr:last-of-type {
			//	border-bottom: 1px solid rgb(208, 32, 33);
			td {
				padding-bottom: 10px;
			}
		}
	}
}

.MuiDialog-root {
	transition: backdrop-filter 0.3s;
	backdrop-filter: blur(1.5px);
}

#msg {
	border-bottom: 1px solid rgba(125, 125, 155, 0.15);
	padding: 10px 0px;
	text-align: center;
}
.MuiPaper-root {
	//background: url(http://api.thumbr.it/whitenoise-361x370.png?background=1d222e00&noise=626262&density=13&opacity=7);
	// background: url(https://old.osut.ro/echipa/adrian_breha2.jpg);
	// background-size: cover;
}

#headerGlobal {
	button {
		margin: 0px 5px;
	}
}

.MuiSelect-select:focus {
	background: none !important; // select input fara background dupa selectare (ex: homepage)
}

.line-wrap {
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.post_mare {
	margin: 0px auto;
	img#poza_articol {
		width: 100%;
		margin: 0px auto;
		display: block;
		border-radius: 10px 10px 0px 0px;
	}
	#bg {
		background-size: 100%;
	}
	#meta {
		height: 100px;
		width: 100%;
		line-height: 100px;
		vertical-align: center;
		color: #eee;
		span,
		div {
			display: inline-block;
			color: red;
			float: left;
			transform: scale(0.7);
		}
		span {
			margin-right: -50px;
			color: #222;
		}
	}
}
@media (max-width: 960px) {
	.post_mare {
		img#poza_articol {
			width: 100%;
			border-radius: 0px;
		}
	}
}

.userway-s6 [role="link"],
.userway-s6 [role="link"],
.userway-s6 a,
.userway-s6 a {
	background: blue !important;
	color: white;
}

/* width */
// ::-webkit-scrollbar {
// 	width: 15px !important;
// }

// /* Track */
// ::-webkit-scrollbar-track {
// 	background: rgba(0, 0, 0, 0.9) !important;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
// 	background: #222732 !important;
// 	border: 2px solid rgba(255, 255, 255, 0.1);
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
// 	background: rgba(255, 255, 255, 0.4) !important;
// }
#contact a {
	color: rgb(208, 33, 33) !important;
	margin-top: 4px;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.switch-wrapper {
	position: relative;
}

#root {
	width: 100%;
}
body.isDark {
	background-color: #1d222e !important;
}
body.isLight {
	background-color: #fff !important;
}
