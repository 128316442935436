#containerAnunturi {
  margin-bottom: 40px;
}

#containerAnunturi .slide {
  background: transparent;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

#containerAnunturi #slid {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 15px;
}

.isLight #containerAnunturi #slid {
  background: white;
}

@media screen and (min-width: 1000px) {
  #containerAnunturi .slide {
    background-image: url(https://redirectioneaza.ro/images/logo-smaller.png);
    background-position-x: right;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
