body,
html {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0px;
}

body img,
html img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body #root,
html #root {
  height: 100%;
}

.unselectable {
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

::-moz-selection {
  background: #df2f2f;
  color: #eee;
}

::selection {
  background: #df2f2f;
  color: #eee;
}

section#continut_articol {
  margin-top: 36px;
}

section#continut_articol h1,
section#continut_articol h2,
section#continut_articol h3,
section#continut_articol h4,
section#continut_articol h5,
section#continut_articol h6 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin: 0px;
  margin-bottom: 20px;
  font-weight: 100;
}

section#continut_articol a {
  color: #d02021;
  padding: 2px;
  text-decoration: none;
}

section#continut_articol a:hover {
  text-decoration: underline;
}

section#continut_articol table {
  width: 100%;
  margin: 30px 0px;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
}

section#continut_articol table td {
  padding-top: 14px;
  vertical-align: top;
  padding-left: 3px;
}

section#continut_articol table tbody tr {
  border-bottom: 1px solid rgba(100, 100, 100, 0.2);
}

section#continut_articol table tbody tr:nth-of-type(even) {
  background-color: rgba(100, 100, 100, 0.1);
}

section#continut_articol table tbody tr:last-of-type td {
  padding-bottom: 10px;
}

.MuiDialog-root {
  -webkit-transition: -webkit-backdrop-filter 0.3s;
  transition: -webkit-backdrop-filter 0.3s;
  transition: backdrop-filter 0.3s;
  transition: backdrop-filter 0.3s, -webkit-backdrop-filter 0.3s;
  -webkit-backdrop-filter: blur(1.5px);
          backdrop-filter: blur(1.5px);
}

#msg {
  border-bottom: 1px solid rgba(125, 125, 155, 0.15);
  padding: 10px 0px;
  text-align: center;
}

#headerGlobal button {
  margin: 0px 5px;
}

.MuiSelect-select:focus {
  background: none !important;
}

.line-wrap {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.post_mare {
  margin: 0px auto;
}

.post_mare img#poza_articol {
  width: 100%;
  margin: 0px auto;
  display: block;
  border-radius: 10px 10px 0px 0px;
}

.post_mare #bg {
  background-size: 100%;
}

.post_mare #meta {
  height: 100px;
  width: 100%;
  line-height: 100px;
  vertical-align: center;
  color: #eee;
}

.post_mare #meta span,
.post_mare #meta div {
  display: inline-block;
  color: red;
  float: left;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.post_mare #meta span {
  margin-right: -50px;
  color: #222;
}

@media (max-width: 960px) {
  .post_mare img#poza_articol {
    width: 100%;
    border-radius: 0px;
  }
}

.userway-s6 [role="link"],
.userway-s6 [role="link"],
.userway-s6 a,
.userway-s6 a {
  background: blue !important;
  color: white;
}

/* width */
#contact a {
  color: #d02121 !important;
  margin-top: 4px;
  text-decoration: none;
}

#contact a:hover {
  text-decoration: underline;
}

.switch-wrapper {
  position: relative;
}

#root {
  width: 100%;
}

body.isDark {
  background-color: #1d222e !important;
}

body.isLight {
  background-color: #fff !important;
}
