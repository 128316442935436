#meniuMobil {
  background: rgba(29, 34, 46, 0.8);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  position: fixed;
  left: 0px;
  top: 60px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding: 40px 0px;
}

#meniuMobil a {
  color: #fff;
  margin: 20px 0px;
  display: block;
  text-transform: uppercase;
  opacity: 1;
}

#meniuMobil a:hover, #meniuMobil a.selected {
  -webkit-transform: skewX(4deg);
          transform: skewX(4deg);
  color: #0cc;
}

a.selected > button {
  background: rgba(160, 160, 205, 0.2);
}

a.selected > button:hover {
  background: rgba(160, 160, 205, 0.2);
}

a {
  overflow: hidden;
  position: relative;
}

a .dropdown {
  width: auto;
  padding: 15px;
  position: absolute;
  top: 15px;
  left: 0px;
  background: rgba(29, 34, 46, 0.98);
  border: 1px solid rgba(200, 200, 200, 0.3);
  min-width: 220px;
  border-radius: 4px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-backdrop-filter: blur(10.5px);
          backdrop-filter: blur(10.5px);
  opacity: 0;
  -webkit-animation-name: dd2;
          animation-name: dd2;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}

a .dropdown a,
a .dropdown button {
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #eee !important;
  text-align: left;
}

a .dropdown a:hover,
a .dropdown button:hover {
  text-decoration: none;
  padding-left: 5px;
}

a .dropdown a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  margin: 0px;
  margin-top: 8px;
}

a:hover {
  overflow: visible;
}

a:hover .dropdown {
  -webkit-animation-name: dd;
          animation-name: dd;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  top: 36px;
  opacity: 1;
  display: block;
}

@-webkit-keyframes dd {
  from {
    opacity: 0;
    top: 0px;
  }
  to {
    opacity: 1;
    top: 36px;
  }
}

@keyframes dd {
  from {
    opacity: 0;
    top: 0px;
  }
  to {
    opacity: 1;
    top: 36px;
  }
}

@-webkit-keyframes dd2 {
  from {
    opacity: 1;
    top: 36px;
  }
  to {
    opacity: 0;
    top: 15px;
  }
}

@keyframes dd2 {
  from {
    opacity: 1;
    top: 36px;
  }
  to {
    opacity: 0;
    top: 15px;
  }
}

#mainAppBar {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

#whitebg {
  padding: 0px !important;
  margin: 0px !important;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: rgba(29, 34, 46, 0.9) !important;
}

.light_mode #whitebg {
  padding: 0px !important;
  margin: 0px !important;
  background: rgba(255, 255, 255, 0.9) !important;
}

#main_nav button {
  margin: 0px 3px;
}

#main_nav a {
  text-decoration: none;
}

.MuiBottomNavigationAction-root {
  min-width: 75px !important;
}

.dark_mode .MuiBottomNavigationAction-root.Mui-selected {
  color: #eee !important;
}

.light_mode .MuiBottomNavigationAction-root.Mui-selected {
  color: #222 !important;
}

/*


















*/
.snowflake {
  color: #fff;
  font-size: 3em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  90% {
    opacity: 1;
  }
  100% {
    top: 64px;
    opacity: 0;
  }
}

@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%;
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    top: 90%;
    opacity: 0;
  }
}

@keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}

/* Demo Purpose Only*/
.demo {
  font-family: "Raleway", sans-serif;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}

.demo a {
  font-family: "Raleway", sans-serif;
  color: #000;
}
