footer#websiteFooter {
	padding: 50px 0px;
	margin: 0px;
	border: none;
	box-shadow: none;
	border-top: 1px solid rgba(180, 180, 180, 0.1);
	margin-top: 60px;
	a.footerLink{
		transition:all .3s;
		margin-bottom:5px;
		padding:2px;
		display:inline-block;
		position:relative;
		width:100%;
		height:22px;
		overflow:hidden;
		p{
			position:absolute;
			left:0px;
			bottom:0px;
			width:100%;
			height:22px;
		}
		div.bg{
			position:absolute;
			left:-10px;
			bottom:0px;
			background:transparent;
			width:0px;
			transition:all .3s;
			height:69%;
			z-index:0;
		}
		&:hover{
			div.bg{
				background:#fff;
				opacity:0.1;
				width:70%;
				padding:-10px;
			}
		}
	}
	button {
		margin-right: 6px;
		opacity: 0.8;
		&:hover {
			opacity: 1;
		}
	}
	&.whitebg {
		border-radius: 0px;
	}
	.footer_heading_meniu {
		font-size: 90%;
		font-weight: 800;
		margin-bottom: 10px;
		text-transform: uppercase;
	}
	a {
		text-decoration: none;
		text-align: left;
		button {
			border-bottom: 2px solid transparent;
			padding-bottom: 0px;
			margin-bottom: 3px;
			transition: all 0.3s;
		}
		
		&:hover {
			text-decoration: none;
			button {
				text-decoration: none;
				border-color: none;
				background:none;
			
			}
		}
	}
	#made {
		text-align: center;
		font-size: 12px;
		opacity: 0.8;
		padding-bottom: 35px;
		#inima {
			color: #dd2020;
			webkit-animation: inima;
			animation: inima;
			-webkit-animation-duration: 2.5s;
			animation-duration: 2.5s;
			-webkit-transform: scale(0.8);
			transform: scale(0.8);
			display: inline-block;
			-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
			padding: 0px 4px;
		}
	}
}
@-webkit-keyframes inima {
	0% {
		-webkit-transform: scale(1.4);
		transform: scale(1.4);
	}
	50% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
	100% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3);
	}
}
@keyframes inima {
	0% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3);
	}
	50% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
	100% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3);
	}
}
